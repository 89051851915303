<template>
  <div>
    <header>
      <div style="width: 20px;"></div>
      <div class="title">餐厅投票</div>
      <h1 class="logo">
        <a href="javascript:void(0);">
          {{ userInfo.avatar }}
          <img
            style="border-radius: 50%"
            src="/img/wxshare/logo-weiyegg.png"
            alt=""
            v-if="!userInfo.userImg"
          />
          <img style="border-radius: 50%" :src="userInfo.userImg" alt="" v-else />
        </a>
      </h1>
    </header>
    <main>
      <div class="main-vote">
        <label for="satisfie" class="label-box">
          <span class="out-checkbox" :class="model?'is-checked':''">
            <span class="inner-checkbox"></span>
            <input type="checkbox" class="input-checkbox" id="satisfie" v-model="model" @change="handleChange($event, model)">
          </span>
          <span class="label-checkbox">满意</span>
        </label>

        <label for="disatisfie" class="label-box">
          <span class="out-checkbox" :class="disModel?'is-checked':''">
            <span class="inner-checkbox"></span>
            <input type="checkbox" class="input-checkbox" id="disatisfie" v-model="disModel" @change="disHandleChange($event, disModel)">
          </span>
          <span class="label-checkbox">不满意</span>
        </label>
      </div>

      <div class="form-item-textarea">
        <textarea id="userMore" rows="10" name="userMore" placeholder="请输入意见！" v-model="txtArea"></textarea>
      </div>

      <div class="save-area">
        <button class="view-static" @click="viewStatic">查看统计</button>
        <button class="save" @click="save">投票</button>
      </div>
    </main>
  </div>
  
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref} from "vue";
import { useRouter, useRoute } from "vue-router";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import $ from "jquery";
import qs from "qs";
export default defineComponent({
  name: "VoteTest",
  components: {
  },
  setup() {
    const model = ref(false)
    const disModel = ref(false)
    const txtArea = ref('')
    const type = ref('')
    const router = useRouter();
    const route = useRoute();
    //  展示用户信息
    const userInfo = inject("userInfo") as any;

    onMounted(() => {
      $('.home-wapper').css('padding-top', '0')
      if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          window.location.href = 'https://www.bzwz.com/User/CanteenVoting.html'
      }
    })

    function handleChange(event: any, model: any) {
      if (model) {
        disModel.value = false
        type.value = '1'
      }
    }

    function disHandleChange(event: any, disModel: any) {
      if (disModel) {
        model.value = false
        type.value = '2'
      }
    }

    function viewStatic() {
      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      } else {
        router.push({
          name: "VoteView"
        });
      }
    }

    function save() {
      if (!type.value) {
        Toast({
          type: "error",
          title: '请选择满意或不满意！',
        });
        return false
      }
      const data = qs.stringify({
        remark: txtArea.value,
        type: type.value
      });
      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      } else {
        axios
        .post("/M/UserServer/canteenVoting", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                router.push({
                  name: "VoteView"
                });
                txtArea.value = ''
              },
            });
            
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err)
        });
      }
    }

    return {
      userInfo,
      model,
      disModel,
      handleChange,
      disHandleChange,
      txtArea,
      save,
      viewStatic
    }
  },
});
</script>

<style lang="scss" scoped>
header {
  width: 375px;
  height: 55px;
  box-sizing: border-box;
  position: fixed;
  padding: 0 10px;
  padding-left: 20px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 10;
  border-bottom: 1px solid #eee;
}
header .title {
  width: 8rem;
  font-size: 18px;
  text-align: center;
}
.logo {
  width: 27px;
  height: 27px;
  margin: 0;
}
.logo a {
  display: block;
  width: 27px;
  height: 27px;
}
.logo img {
  width: 27px;
  height: 27px;
  display: block;
}

main {
  margin-top: 55px;
  padding: 20px;
  padding-top: 30px;
}

.main-vote {
  display: flex;
  justify-content: space-around;
}


.label-box {
    color: #606266;
    font-weight: 500;
    font-size: .37333rem;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
}


.out-checkbox {
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    outline: none;
    line-height: 1;
    vertical-align: middle;
}

.inner-checkbox {
    display: inline-block;
    position: relative;
    border: .02667rem solid #dcdfe6;
    border-radius: .05333rem;
    box-sizing: border-box;
    width: .53333rem;
    height: .53333rem;
    background-color: #fff;
    z-index: 1;
    -webkit-transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
    transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
}

.inner-checkbox::after {
    box-sizing: content-box;
    content: "";
    border: .02667rem solid #fff;
    border-left: 0;
    border-top: 0;
    height: .26667rem;
    left: .16rem;
    position: absolute;
    top: .02667rem;
    -webkit-transform: rotate(
    45deg
    ) scaleY(0);
        -ms-transform: rotate(45deg) scaleY(0);
        transform: rotate(
    45deg
    ) scaleY(0);
    width: .13333rem;
    -webkit-transition: -webkit-transform .15s ease-in .05s;
    transition: -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s,-webkit-transform .15s ease-in .05s;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
}

.input-checkbox {
  opacity: 0;
  outline: none;
  position: absolute;
  margin: 0;
  width: 0;
  height: 0;
  z-index: -1;
}

.out-checkbox.is-checked .inner-checkbox {
    background-color: #df0024;
    border-color: #df0024;
}

.out-checkbox.is-checked .inner-checkbox:after {
    transform: rotate(45deg) scaleY(1);
}

.label-checkbox {
  display: inline-block;
  padding-left: .26667rem;
  line-height: .50667rem;
  font-size: .37333rem;
}

.form-item-textarea {
  margin-top: 25px;
}

.form-item-textarea textarea {
  width: 100%;
  // text-align: right;
  // outline: none;
  // border: none;
  border: 1px solid #eee;
  padding-right: 10px;
  box-sizing: border-box;
  font-family: Arial;
  margin-top: 10px;
  padding: 10px;
  outline: none;
}

.save-area {
  width: 100%;
  height: 1.2rem;
  position: relative;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}

.view-static {
  display: inline-block;
  width: 40%;
  height: 1.2rem;
  background-color: #999;
  box-shadow: 0 0.13333rem 0.26667rem 0 rgb(0 0 0 / 30%);
  border-radius: 0.1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff !important;
  font-size: 0.4rem;
  border: none;
  outline: none;
}

.save {
  // margin-left: 10%;
  width: 40%;
  height: 1.2rem;
  background-color: #d64b4b;
  box-shadow: 0 0.13333rem 0.26667rem 0 rgb(0 0 0 / 30%);
  border-radius: .1rem;
  line-height: 1.2rem;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  font-size: .4rem;
}
</style>