
import { defineComponent, inject, onMounted, ref} from "vue";
import { useRouter, useRoute } from "vue-router";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import $ from "jquery";
import qs from "qs";
export default defineComponent({
  name: "VoteTest",
  components: {
  },
  setup() {
    const model = ref(false)
    const disModel = ref(false)
    const txtArea = ref('')
    const type = ref('')
    const router = useRouter();
    const route = useRoute();
    //  展示用户信息
    const userInfo = inject("userInfo") as any;

    onMounted(() => {
      $('.home-wapper').css('padding-top', '0')
      if (!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          window.location.href = 'https://www.bzwz.com/User/CanteenVoting.html'
      }
    })

    function handleChange(event: any, model: any) {
      if (model) {
        disModel.value = false
        type.value = '1'
      }
    }

    function disHandleChange(event: any, disModel: any) {
      if (disModel) {
        model.value = false
        type.value = '2'
      }
    }

    function viewStatic() {
      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      } else {
        router.push({
          name: "VoteView"
        });
      }
    }

    function save() {
      if (!type.value) {
        Toast({
          type: "error",
          title: '请选择满意或不满意！',
        });
        return false
      }
      const data = qs.stringify({
        remark: txtArea.value,
        type: type.value
      });
      const token = localStorage.getItem("token");
      if (!token) {
          Modal({
              title: "温馨提示",
              content: "登录后才可以进行投票！",
              onClose: () => {
                  router.push({
                      name: "Login",
                      query: {
                          redirect: router.currentRoute.value.fullPath,
                      },
                  });
              },
          });
          return false;
      } else {
        axios
        .post("/M/UserServer/canteenVoting", data, {
          params: {
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                router.push({
                  name: "VoteView"
                });
                txtArea.value = ''
              },
            });
            
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err)
        });
      }
    }

    return {
      userInfo,
      model,
      disModel,
      handleChange,
      disHandleChange,
      txtArea,
      save,
      viewStatic
    }
  },
});
